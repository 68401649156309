import React, { useState } from 'react';
import { format } from 'date-fns';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import ClearIconCustom from '@mui/icons-material/Clear';

const { v4: uuidv4 } = require('uuid');

function createKey(input) {
  if (typeof input !== 'string') return uuidv4();
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

// function isDisrupted(disrupt) {
//   if (!disrupt) return null;
//   return <CheckIcon />;
// }

const createHeadCaseTable = (withWidth) => ({
  cells: [
    {
      key: 'Ville Départ',
      content: 'Ville Départ',
      isSortable: false,
      width: withWidth ? 15 : undefined,
    },
    {
      key: 'Ville Arrivée',
      content: 'Ville Arrivée',
      isSortable: false,
      width: withWidth ? 15 : undefined,
    },
    {
      key: 'Marketing airline name',
      content: 'Marketing airline name',
      isSortable: false,
      width: withWidth ? 15 : undefined,
    },
    {
      key: 'Marketing flight n°',
      content: 'Marketing flight n°',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Operating airline name',
      content: 'Operating airline name',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Operating flight n°',
      content: 'Operating flight n°',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 8 : undefined,
    },
    {
      key: 'Date du vol',
      content: 'Date du vol',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 12 : undefined,
    },
    {
      key: 'Départ',
      content: 'Départ',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 11 : undefined,
    },
    {
      key: 'Arrivée',
      content: 'Arrivée',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 11 : undefined,
    },
    {
      key: 'Vol disrupté',
      content: 'Vol perturbé',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 8 : undefined,
    },
    {
      key: 'X',
      content: '',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 8 : undefined,
    },
  ],
});

const EditableCell = ({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleSave = () => {
    setIsEditing(false);
    onSave(currentValue);
  };

  const renderInput = () => {
    console.log('currentValue', currentValue, typeof currentValue);
    if (typeof currentValue === 'boolean') {
      return (
        <Checkbox
          checked={currentValue}
          onChange={(e) => setCurrentValue(e.target.checked)}
          onBlur={handleSave}
        />
      );
    } else if (currentValue instanceof Date) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DatePicker
            value={currentValue}
            onChange={(date) => {
              setCurrentValue(date)
              setIsEditing(false)
              onSave(date)
            }}
            onBlur={handleSave}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      );
    } else {
      return (
        <TextField
          type="text"
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          onBlur={handleSave}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSave();
            }
          }}
        />
      );
    }
  };

  return isEditing ? (
    renderInput()
  ) : (
    <span onClick={() => setIsEditing(true)}>
    {currentValue instanceof Date ? format(new Date(currentValue), 'dd/MM/yyyy') : currentValue.toString()}
  </span>
  );
};

const rowsCaseTable = (data, onSave, deleteFlight) => data.map((flight, index) => { 
  console.log({flight})
  return ({
  key: `row-${index}-${flight.id}`,
  cells: [
    {
      key: createKey(flight.departure_airport?.search_text),
      content: (<EditableCell
        value={flight.departure_airport?.search_text}
        onSave={(newValue) => onSave(index, 'departure_airport.search_text', newValue)}
      />),
    },
    {
      key: createKey(flight.arrival_airport?.search_text),
      content: (<EditableCell
        value={flight.arrival_airport?.search_text}
        onSave={(newValue) => onSave(index, 'arrival_airport.search_text', newValue)}
      />),
    },
    {
      key: createKey(flight.marketing_airline.display_name),
      content: (<EditableCell
        value={flight.marketing_airline.display_name}
        onSave={(newValue) => onSave(index, 'marketing_airline.display_name', newValue)}
      />),
    },
    {
      key: createKey(flight.marketing_airline.flight_number),
      content: (<EditableCell
        value={flight.marketing_airline.flight_number}
        onSave={(newValue) => onSave(index, 'marketing_airline.flight_number', newValue)}
      />),
    },
    {
      key: createKey(flight.operating_airline.display_name),
      content: (<EditableCell
        value={flight.operating_airline.display_name}
        onSave={(newValue) => onSave(index, 'operating_airline.display_name', newValue)}
      />),
    },
    {
      key: createKey(flight.flight_details.flight_number),
      content: (<EditableCell
        value={flight.flight_details.flight_number}
        onSave={(newValue) => onSave(index, 'flight_details.flight_number', newValue)}
      />),
    },
    {
      key: createKey(flight.flight_details.flight_date),
      content: (<EditableCell
        value={new Date(flight.flight_details.flight_date)}
        onSave={(newValue) => onSave(index, 'flight_details.flight_date', newValue)}
      />),
    },
    {
      key: createKey(flight.flight_details.departure_airport_name),
      content: (<EditableCell
        value={flight.flight_details.departure_airport_name}
        onSave={(newValue) => onSave(index, 'flight_details.departure_airport_name', newValue)}
      />),
    },
    {
      key: createKey(flight.flight_details.arrival_airport_name),
      content: (<EditableCell
        value={flight.flight_details.arrival_airport_name}
        onSave={(newValue) => onSave(index, 'flight_details.arrival_airport_name', newValue)}
      />),
    },
    {
      key: createKey(flight.disrupted),
      content: (<EditableCell
        value={flight.disrupted}
        onSave={(newValue) => onSave(index, 'disrupted', newValue)}
      />),
    },
    {
      key: createKey(index),
      content: index === 0 ? null : (
        <button onClick={() => deleteFlight(index)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <ClearIconCustom color="error" fontSize="small" />
        </button>
      ),
    },
  ],
})});

export {
  createHeadCaseTable,
  rowsCaseTable,
};
